body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar{
  display: none !important;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Light.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Light.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A Light.ttf") format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Regular.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Medium.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Medium Italic.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A Medium Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A SemiBold.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A SemiBold Italic.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A SemiBold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Bold.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Bold Italic.ttf'),
  url("/src/assets/fonts/euclid-a/Euclid Circular A Bold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: "RayleighGlamour";
  src: local("RayleighGlamourRegular.otf"), url("/src/assets/fonts/rayleigh-glamour/RayleighGlamourRegular.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SatrevaNova";
  src: local("SatrevaNova.ttf"), url("/src/assets/fonts/satreva-nova/SatrevaNova.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OgelicRegular";
  src: local("OgelicRegular.ttf"), url("/src/assets/fonts/ogelic/OgelicRegular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}







@font-face {
  font-family: "RayleighGlamour";
  src: local("RayleighGlamourRegular.otf"), url("/src/assets/fonts/rayleigh-glamour/RayleighGlamourRegular.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SatrevaNova";
  src: local("SatrevaNova.ttf"), url("/src/assets/fonts/satreva-nova/SatrevaNova.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "UberMoveAR";
  src: local("UberMoveARRegular.ttf"), url("/src/assets/fonts/uber-move-ar/UberMoveARRegular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "UberMoveAR";
  src: local("UberMoveARLight.ttf"), url("/src/assets/fonts/uber-move-ar/UberMoveARLight.ttf") format("truetype");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: "UberMoveAR";
  src: local("UberMoveARRegular.ttf"), url("/src/assets/fonts/uber-move-ar/UberMoveARRegular.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SamsungSharpSans";
  src: local("SamsungSharpSansRegular.ttf"), url("/src/assets/fonts/samsung-sharp-sans/SamsungSharpSansRegular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SamsungSans";
  src: local("SamsungSans-Regular.ttf"), url("/src/assets/fonts/samsung-sans/SamsungSans-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SamsungSans";
  src: local("SamsungSans-Light.ttf"), url("/src/assets/fonts/samsung-sans/SamsungSans-Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SamsungSans";
  src: local("SamsungSans-Regular.ttf"), url("/src/assets/fonts/samsung-sans/SamsungSans-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SamsungSans";
  src: local("SamsungSans-Bold.ttf"), url("/src/assets/fonts/samsung-sans/SamsungSans-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

